import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import { Heading } from "../components/Hero"
import { Container, Section } from "../components/Layouts"
import SEO from "components/seo"
import Youtube from "../components/Youtube"
import { ContactForm } from "../components/Forms"
import { Text } from "../components/Core"

const PlasticSurgeryMarketingRevolutionPage = ({ data, path }) => {
  const post = data.allUniquePagesJson.nodes[0]

  return (
    <Layout navSpacer className="psmr-page" path={path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />

      <Section className="relative">
        <Container>
          <Text as="h1" text={post.heading} />
        </Container>
      </Section>

      <Section className="relative">
        <Container small>
          <Youtube code="IByzyHngSCo" />
        </Container>
      </Section>

      <Section className="relative">
        <Container className="color-back rounded psmr-page__form-section">
          <div>
            <ContactForm classNames="mt-2 form-light" redirect="/thank-you/" />
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default PlasticSurgeryMarketingRevolutionPage

export const pageQuery = graphql`
  query plasticSurgeryMarketingRevolution($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        body
        image
      }
    }
  }
`
