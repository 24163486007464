import React from "react"
import { Image, Text } from "../Core"
import { Container } from "../Layouts"

const Hero = ({
  heading,
  blurb,
  desktopImage,
  mobileImage,
  noOverlapMobile
}) => {
  return (
    <Container className="hero relative">
      <div className={`hero__heading`}>
        <Text as="h1" text={heading} />
      </div>
      <div className={`hero__image ${noOverlapMobile ? "no-overlap" : ""}`}>
        <Image
          width="1140"
          height="612"
          loading="eager"
          publicId={desktopImage}
          wrapperClassName="hero__image--desktop"
        />
        <Image publicId={mobileImage} wrapperClassName="hero__image--mobile" />
      </div>
      {blurb && <Text text={blurb} className="hero__blurb" />}
    </Container>
  )
}

export default Hero
