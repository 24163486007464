import React from "react"
import MarkdownViewer from "@components/MarkdownViewer"
import { Section, Container } from "../Layouts"
import Button from "../Buttons/Button"

const Heading = ({ content, button, markdown }) => {
  return (
    <Section className="hero__heading">
      <Container>
        {markdown ? (
          <MarkdownViewer markdown={markdown} />
        ) : (
          <h1 dangerouslySetInnerHTML={{ __html: content }} />
        )}
        {button && <Button buttonText={button.buttonText} href={button.href} />}
      </Container>
    </Section>
  )
}

export default Heading
